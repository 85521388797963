<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader>
          <CIcon name="cil-calendar"/>
          Eine neue allgemeine Freizeit / Fortbildung anlegen
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm class="no-mb" @submit.prevent="newFreizeit()">

              <FreizeitText v-model="name" formLabel="Name"/>
              <FreizeitDatum v-model="datum" formLabel="Datum der Freizeit / Fortbildung"/>
              <FreizeitNumber v-model="dauer" formLabel="Dauer der Freizeit / Fortbildung"/>

              <FormError v-if="formError" :errormessage="formError"/>

              <CRow class="mt-4">
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Freizeit / Fortbildung anlegen</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import FreizeitText from '@/components/FormElements/Text'
import FreizeitNumber from '@/components/FormElements/Number'
import FreizeitDatum from '@/components/FormElements/Date'

export default {
  name: 'MitarbeiterNew',
  components: {
    FormError,
    FreizeitText,
    FreizeitNumber,
    FreizeitDatum
  },
  data () {
    return {
      name: '',
      datum: '',
      dauer: 8.00,
      formError: null
    }
  },
  mounted () {
    this.setDefaults()
  },
  methods: {
    setDefaults () {
      this.name = ''
      this.datum = ''
      this.dauer = 8
      this.formError = null
    },
    newFreizeit () {
      Vue.axios.post('/freizeit/post', {
        name: this.name,
        datum: this.datum,
        dauer: this.dauer
      }).then((response) => {
        this.$router.push({ path: '/freizeit/uebersicht' })
        this.$snotify.success('Die neue Freizeit / Fortbildung wurde angelegt.', {
          position: 'rightTop',
          timeout: 4000
        })
        this.setDefaults()
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
